import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "welcome"
    }}>{`Welcome`}</h1>
    <p>{`These are the SwiftQ documentation. This will hopefully guide you through setting up your environment, adding new
code changes and the policies that go with it.`}</p>
    <p>{`The design, concepts and future goals will also be added here so that everyone starts off on the same page.`}</p>
    <p>{`The documentation is using `}<a parentName="p" {...{
        "href": "https://www.docz.site"
      }}>{`docz`}</a>{` with `}<a parentName="p" {...{
        "href": "https://material-ui.com/"
      }}>{`material-ui`}</a></p>
    <h2 {...{
      "id": "adding-pages"
    }}>{`Adding pages`}</h2>
    <p>{`All pages should be under the `}<inlineCode parentName="p">{`./pages`}</inlineCode>{` directory.
New folders can be added to keep the files "clean".
Documentation should try to group documentation together e.g.`}</p>
    <p>{`Pages `}<inlineCode parentName="p">{`/setup`}</inlineCode>{` contains `}<inlineCode parentName="p">{`/environment`}</inlineCode>{` and `}<inlineCode parentName="p">{`/installation`}</inlineCode>{`.
To set this up you only require the `}<inlineCode parentName="p">{`.mdx`}</inlineCode>{` file to point to `}<inlineCode parentName="p">{`/setup/<name here>`}</inlineCode>{` as shown
inside `}<inlineCode parentName="p">{`./setup/install.mdx`}</inlineCode>{` and `}<inlineCode parentName="p">{`./setup/environment`}</inlineCode>{`.`}</p>
    <p>{`When making big changes, like paths on a new file or changes to `}<inlineCode parentName="p">{`doczrc.js`}</inlineCode>{` rerun the development command.
See below :point_down:`}</p>
    <h2 {...{
      "id": "running-development"
    }}>{`Running development`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`npm run dev
`}</code></pre>
    <p>{`Open the browser at http://localhost:3000`}</p>
    <h2 {...{
      "id": "deployment"
    }}>{`Deployment`}</h2>
    <p><strong parentName="p">{`NEVER PUSH TO MASTER`}</strong>{`.`}</p>
    <p>{`Create a new branch and add all of your changes there. Then create a PR (pull request) to master branch, which
will auto-deploy to `}<a parentName="p" {...{
        "href": "https://develop.swiftq.app"
      }}>{`https://develop.swiftq.app`}</a></p>
    <h2 {...{
      "id": "build"
    }}>{`Build`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`npm build
`}</code></pre>
    <h2 {...{
      "id": "serve-built-app"
    }}>{`Serve built app`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`npm serve
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      